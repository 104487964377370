export default {
  users: {
    name: '',
    surname: '',
    email: '',
    password: '',
    password_confirmation: '',
    access_token: '',
    role: '',
    municipality_id: '',
    gender: '',
    gdpr_consent: '',
    terms_consent: '',
    shirt_size: '',
    address: '',
    city: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    roles: {
      jsonApi: 'hasOne',
      type: 'roles'
    },
    municipalities: {
      jsonApi: 'hasOne',
      type: 'municipalities'
    }
  },
  roles: {
    name: '',
    slug: ''
  },
  pois: {
    name: '',
    slug: '',
    location: {
      lat: '',
      lng: ''
    },
    position: '',
    description: '',
    external_description: '',
    external_views: '',
    external_url: '',
    municipality_id: '',
    poi_attributes_count: '',
    type_id: '',
    created_at: '',
    updated_at: '',
    created_by: '',
    parent_id: '',
    publish_state: '',
    review_state: '',
    suggestion_state: '',
    poi_types: {
      jsonApi: 'hasOne',
      type: 'poi_types'
    },
    poi_attributes: {
      jsonApi: 'hasMany',
      type: 'poi_attributes'
    },
    regions: {
      jsonApi: 'hasOne',
      type: 'regions'
    },
    municipalities: {
      jsonApi: 'hasOne',
      type: 'municipalities'
    },
    poi_media: {
      jsonApi: 'hasMany',
      type: 'poi_media'
    },
    interests: {
      jsonApi: 'hasMany',
      type: 'interests'
    },
    author: {
      jsonApi: 'hasOne',
      type: 'users'
    }
  },
  poi_types: {
    slug: '',
    name: '',
    icon: '',
    is_default: false,
    pois_count: '',
    created_at: '',
    updated_at: '',
    meta: {},
    pivot: {},
    pois: {
      jsonApi: 'hasMany',
      type: 'pois'
    },
    poi_attributes: {
      jsonApi: 'hasMany',
      type: 'poi_attributes'
    },
    interests: {
      jsonApi: 'hasMany',
      type: 'interests'
    },
    description_instructions: {
      jsonApi: 'hasMany',
      type: 'description_instructions'
    }
  },
  poi_attributes: {
    slug: '',
    name: '',
    value: '',
    helper_text: '',
    multiple_choice: false,
    meta: {},
    pivot: {},
    unit: '',
    options: [],
    type: '',
    created_at: '',
    updated_at: ''
  },
  regions: {
    name: '',
    centroid: '',
    slug: '',
    area: ''
  },
  municipalities: {
    name: '',
    centroid: '',
    slug: '',
    area: '',
    region_id: '',
    regions: {
      jsonApi: 'hasOne',
      type: 'regions'
    }
  },
  poi_media: {
    media_type: '',
    meta: {},
    pivot: {},
    exif: {},
    order: '',
    original_width: '',
    original_height: '',
    original_size: '',
    poi_id: '',
    created_at: '',
    updated_at: '',
    uploaded_by: '',
    is_finalist: '',
    files: {},
    pois: {
      jsonApi: 'hasOne',
      type: 'pois'
    },
    uploaders: {
      jsonApi: 'hasOne',
      type: 'uploaders'
    }
  },
  interests: {
    name: '',
    slug: '',
    pois_count: '',
    meta: {},
    pivot: {},
    created_at: '',
    updated_at: '',
    pois: {
      jsonApi: 'hasMany',
      type: 'pois'
    },
    poi_types: {
      jsonApi: 'hasMany',
      type: 'poi_types'
    },
    poi_attributes: {
      jsonApi: 'hasMany',
      type: 'poi_attributes'
    }
  },
  description_instructions: {
    title: '',
    instructions_title: '',
    instructions_main: '',
    instructions_more: '',
    order: ''
  },
  poi_descriptions: {
    content: '',
    type: '',
    user_id: '',
    poi_id: '',
    created_at: '',
    updated_at: ''
  },
  uploaders: {
    name: '',
    email: '',
    created_at: '',
    updated_at: '',
    poi_media_count: '',
    poi_media: {
      jsonApi: 'hasMany',
      type: 'poi_media'
    },
    pois: {
      jsonApi: 'hasMany',
      type: 'pois'
    }
  }
};
