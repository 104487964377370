import prependUrlBaseProxy from './prependBaseProxy';

export const base = '/app/admin/';

const urlsWithoutBase = {
  base,
  dashboard: 'dashboard',
  pois: {
    list: `pois`,
    create: `pois/create`,
    edit: (id) => `pois/${id}/edit`,
    single: (id) => `pois/${id}`,
    taxonomies: `pois/taxonomies`,
    suggestions: 'pois/suggestions',
    suggestion: (id) => `pois/suggestions/${id}`
  },
  users: {
    list: `users`,
    create: `users/create`,
    edit: (id) => `users/${id}/edit`,
    single: (id) => `users/${id}`
  },
  photos: {
    list: 'photos'
  },
  contest: {
    photos: 'contest/photos',
    uploaders: 'contest/uploaders'
  },
  account: `account`,
  iconsTest: 'icons-test',
  apiTest: 'api-test'
};

export const urls = new Proxy(urlsWithoutBase, prependUrlBaseProxy);

export default urls;
