import React from 'react';

const Logo = ({full, ...props}) => {
  if (full) {
    return (
      <img alt="Logo" src="/static/logo-full.svg" {...props} style={{ height: '60px', width: '120px' }} />
    );
  }
  return <img alt="Logo" src="/static/logo.svg" {...props} style={{ height: '50px', width: '60px' }} />;
};

export default Logo;
