import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default function TermsDialog({ title, handleConfirm, children, disabled }) {
  return (
    <Dialog open={true}>
      <DialogTitle>Dragi novi član, </DialogTitle>
      <DialogContent>
        <Typography paragraph> čaka te samo še strinjanje s pogoji uporabe in si naš ;) </Typography>
        <Typography paragraph>
          Strinjam se, da spletna stran kamzavikend.si hrani moje osebne podatke za namene identifikacije in
          prijave v portal. Spletna stran kamzavikend.si nikoli ne bo uporabila tvojih osebnih podatkov za
          oglaševalske namene. Prav tako osebnih podatkov ne bomo delili s tretjimi osebami.
        </Typography>
        <Typography paragraph>
          V primeru da želiš, da v celoti izbrišemo tvoje osebne podatke, nam piši na{' '}
          <strong>support@kamzavikend.si</strong>.
        </Typography>
        <Typography paragraph>
          Strinjam se, da je vsa moja naložena vsebina (besedila, fotografije, video vsebine, podatki ipd.) na
          www.kamzavikend.si last portala www.kamzavikend.si in da jo lahko uporabljamo za tržne,
          komunikacijske ali kakršnekoli druge namene (tiskovine, deljenje na družbenih omrežjih ipd.).
        </Typography>
        <Typography paragraph>
          Strinjam se, da naložena vsebina ostane last portala kamzavikend.si četudi se izbriše moje osebne
          podatke. Vsebina tako ne bo izbrisana - izbrisani bodo samo osbeni podatki.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="contained" disabled={disabled}>
          Strinjam se
        </Button>
      </DialogActions>
    </Dialog>
  );
}
