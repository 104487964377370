import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

const MemberGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) return <Redirect to="/login" />;
  if (!user || user.role !== 'member') return <Redirect to="/login" />;

  return <>{children}</>;
};

MemberGuard.propTypes = {
  children: PropTypes.node
};

export default MemberGuard;
