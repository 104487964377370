import { deserialize } from 'deserialize-json-api';
import schema from './schema';

export const transformURLUnderscoresToDashes = {
  name: 'underscores-to-dashes',
  req: (payload) => {
    payload.req.url = payload.req.url.replaceAll('_', '-');
    return payload;
  }
};

function mergeObjects(schema, overrides) {
  return {
    ...schema,
    ...overrides
  };
}

function addMissingFieldsFromSchema(data) {
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const model = data[i];
      const modelSchema = schema[model.type];
      data[i] = mergeObjects(modelSchema, model);
      for (const key in model) {
        if (key in schema) {
          // We have a nested model
          // Recurse
          const nestedModel = model[key];
          data[i][key] = addMissingFieldsFromSchema(nestedModel);
        }
      }
    }
  } else {
    const type = data.type;
    if (type in schema) {
      data = mergeObjects(schema[type], data);
    }
  }

  return data;
}

function transformSchemaRelationshipsToDefaultValues(data) {
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const model = data[i];

      for (const key in model) {
        const element = model[key];
        if (Array.isArray(element)) {
          // Nested array. Recurse.
          model[key] = transformSchemaRelationshipsToDefaultValues(element);
        } else {
          if (model[key]?.jsonApi === 'hasMany') {
            model[key] = [];
          } else if (model[key]?.jsonApi === 'hasOne') {
            model[key] = {};
          }
        }
      }
    }
  } else {
    for (const key in data) {
      if (data[key]?.jsonApi === 'hasMany') {
        data[key] = [];
      } else if (data[key]?.jsonApi === 'hasOne') {
        data[key] = {};
      }
    }
  }

  return data;
}

export const customDeserializer = {
  name: 'deserializer',
  res: (payload) => {
    // We are using different deserializer than default TWG/devour deserializer
    // because default one does not support meta data on relationships
    let deserialized = deserialize(payload.res.data);
    deserialized.data = addMissingFieldsFromSchema(deserialized.data);
    deserialized.data = transformSchemaRelationshipsToDefaultValues(deserialized.data);
    return deserialized;
  }
};
