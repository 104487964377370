import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { base as adminBase } from 'src/routes/adminUrls';
import { base as memberBase } from 'src/routes/memberUrls';

const MemberOrAdminGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) return <Redirect to="/login" />;
  else {
    if (user) {
      if (user.role === 'member') {
        return <Redirect to={memberBase} />;
      } else if (user.role === 'admin') {
        return <Redirect to={adminBase} />;
      }
    } else {
      return <Redirect to="/login" />;
    }
  }
};

MemberOrAdminGuard.propTypes = {
  children: PropTypes.node
};

export default MemberOrAdminGuard;
