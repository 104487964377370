const prependUrlBase = {
  get: (target, key) => {
    if (typeof target[key] === 'object' && target[key] !== null) {
      // Handle nested object
      // Base property is defined only in top-level parent object, so pass it down
      return new Proxy({ base: target['base'], ...target[key] }, prependUrlBase);
    } else {
      if (key === '$$typeof') return key;
      if (typeof target[key] === 'undefined') {
        return console.error(`Url ${key} not defined.`);
      }
      if (typeof target[key] === 'function') {
        return (value) => target['base'] + target[key](value);
      } else {
        return target['base'] + target[key];
      }
    }
  },
  set() {
    return false;
  },
  deleteProperty() {
    return false;
  }
};

export default prependUrlBase;
