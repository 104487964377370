import { useQuery } from 'react-query';
import { get, fetchCurrentUser } from './methods';

// TODO: Can be refactored with object Proxy
export const QUERY_KEYS = {
  pois: (includes) => constructQueryKey('pois', null, includes),
  poi: (id, includes) => constructQueryKey('poi', id, includes),
  poiTypes: (includes) => constructQueryKey('poi_types', null, includes),
  poiType: (id, includes) => constructQueryKey('poi_types', id, includes),
  interests: (includes) => constructQueryKey('interests', null, includes),
  regions: (includes) => constructQueryKey('regions', null, includes),
  municipalities: (includes) => constructQueryKey('municipalities', null, includes),
  poiAttributes: (includes) => constructQueryKey('poi_attributes', null, includes),
  users: (includes) => constructQueryKey('users', null, includes),
  user: (id, includes) => constructQueryKey('user', id, includes),
  currentUser: () => constructQueryKey('current_user'),
  media: (includes, params) => constructQueryKey('poi_media', null, includes, params),
  singleMedia: (id, includes) => constructQueryKey('poi_media', id, includes),
  uploaders: (includes) => constructQueryKey('uploaders', null, includes)
};

const constructQueryKey = (type, id, includes = [], params = {}) => {
  const queryKey = [type];
  if (id) queryKey.push(id);
  if (includes.length) queryKey.push(includes.join(','));
  if (!!Object.keys(params).length) queryKey.push(JSON.stringify(params));
  return queryKey;
};

// Pois
export function usePoi(
  id,
  includes = ['municipality', 'region', 'type', 'media', 'poi_attributes', 'interests'],
  queryOptions = {}
) {
  return useQuery(
    QUERY_KEYS.poi(id, includes),
    async () => {
      const { data } = await get({ resourceType: 'pois', id, includes });
      return data;
    },
    queryOptions
  );
}

export function usePois(includes = [], params = {}, queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.pois(includes),
    async () => {
      const { data } = await get({ resourceType: 'pois', includes, ...params });
      return data;
    },
    queryOptions
  );
}

// Poi types
export function usePoiType(id, includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.poiType(id, includes),
    async () => {
      const { data } = await get({ resourceType: 'poi_types', id, includes });
      return data;
    },
    queryOptions
  );
}

export function usePoiTypes(includes = [], queryOptions = {}) {
  return useQuery(QUERY_KEYS.poiTypes(includes), async () => {
    const { data } = await get({ resourceType: 'poi_types', includes });
    return data;
  });
}

// Interests

export function useInterests(includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.interests(includes),
    async () => {
      const { data } = await get({ resourceType: 'interests', includes });
      return data;
    },
    queryOptions
  );
}

// Regions
export function useRegions(includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.regions(includes),
    async () => {
      const { data } = await get({ resourceType: 'regions', includes });
      return data;
    },
    queryOptions
  );
}

// Municipalities
export function useMunicipalities(includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.municipalities(includes),
    async () => {
      const { data } = await get({ resourceType: 'municipalities', includes, 'page[size]': 400 });
      return data;
    },
    queryOptions
  );
}

// Attributes
export function usePoiAttributes(includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.poiAttributes(includes),
    async () => {
      const { data } = await get({ resourceType: 'poi_attributes', includes, 'page[size]': 400 });
      return data;
    },
    queryOptions
  );
}

// Users
export function useUsers(includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.users(includes),
    async () => {
      const { data } = await get({ resourceType: 'users', includes, 'page[size]': 400 });
      return data;
    },
    queryOptions
  );
}

export function useUser(id, includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.user(id, includes),
    async () => {
      const { data } = await get({ resourceType: 'users', id, includes });
      return data;
    },
    queryOptions
  );
}

export function useCurrentUser(queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.currentUser(),
    async () => {
      const { data } = await fetchCurrentUser();
      return data;
    },
    queryOptions
  );
}

// Media
export function useMedia(includes = [], params = {}, queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.media(includes, params),
    async () => {
      const response = await get({ resourceType: 'poi_media', includes, ...params });
      return response;
    },
    queryOptions
  );
}

export function useSingleMedia(id, includes = [], queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.singleMedia(id, includes),
    async () => {
      const { data } = await get({ resourceType: 'poi_media', id, includes });
      return data;
    },
    queryOptions
  );
}

export function useUploaders(includes = [], params = {}, queryOptions = {}) {
  return useQuery(
    QUERY_KEYS.uploaders(includes),
    async () => {
      const { data } = await get({ resourceType: 'uploaders', includes, ...params });
      return data;
    },
    queryOptions
  );
}
