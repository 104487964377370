import React, { lazy } from 'react';
import DashboardLayout from 'src/layouts/AdminLayout';
import AdminGuard from 'src/routes/guards/AdminGuard';
import { Redirect } from 'react-router-dom';
import urls, { base } from './adminUrls';

const adminRoutes = [
  {
    path: base,
    guard: AdminGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: urls.pois.suggestion(':id'),
        component: lazy(() => import('src/views/admin/poi/SuggestionView'))
      },
      {
        exact: true,
        path: urls.pois.suggestions,
        component: lazy(() => import('src/views/admin/poi/SuggestionsView'))
      },
      {
        exact: true,
        path: urls.pois.list,
        component: lazy(() => import('src/views/admin/poi/ListView'))
      },
      {
        exact: true,
        path: urls.pois.create,
        component: lazy(() => import('src/views/admin/poi/CreateView'))
      },
      {
        exact: true,
        path: urls.pois.taxonomies,
        component: lazy(() => import('src/views/admin/poi/TaxonomiesView'))
      },
      {
        exact: true,
        path: urls.pois.edit(':id'),
        component: lazy(() => import('src/views/admin/poi/EditView'))
      },
      {
        exact: true,
        path: urls.pois.single(':id'),
        component: lazy(() => import('src/views/admin/poi/SingleView'))
      },

      {
        exact: true,
        path: urls.photos.list,
        component: lazy(() => import('src/views/admin/photos/ListView'))
      },

      {
        exact: true,
        path: urls.contest.photos,
        component: lazy(() => import('src/views/admin/contest/Photos'))
      },
      {
        exact: true,
        path: urls.contest.uploaders,
        component: lazy(() => import('src/views/admin/contest/Participants'))
      },

      {
        exact: true,
        path: urls.users.list,
        component: lazy(() => import('src/views/admin/users/ListView'))
      },
      {
        exact: true,
        path: urls.users.create,
        component: lazy(() => import('src/views/admin/users/CreateView'))
      },
      {
        exact: true,
        path: urls.users.edit(':id'),
        component: lazy(() => import('src/views/admin/users/EditView'))
      },
      {
        exact: true,
        path: urls.users.single(':id'),
        component: lazy(() => import('src/views/admin/users/SingleView'))
      },

      {
        exact: true,
        path: urls.account,
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: urls.dashboard,
        component: lazy(() => import('src/views/admin/reports/DashboardView'))
      },
      {
        exact: true,
        path: base,
        component: () => <Redirect to={urls.dashboard} />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default adminRoutes;
