import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { CircularProgress } from '@material-ui/core';

const LoadingScreen = () => {
  NProgress.configure({ topPosition: '100vh' });
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 150
      }}
    >
      <CircularProgress></CircularProgress>
    </div>
  );
};

export default LoadingScreen;
