import React, { lazy } from 'react';
import MemberLayout from 'src/layouts/MemberLayout';
import MemberGuard from 'src/routes/guards/MemberGuard';
import { Redirect } from 'react-router-dom';
import urls, { base } from './memberUrls';

const memberRoutes = [
  {
    path: base,
    guard: MemberGuard,
    layout: MemberLayout,
    routes: [
      {
        exact: true,
        path: urls.dashboard,
        component: lazy(() => import('src/views/member'))
      },
      {
        exact: true,
        path: urls.suggestions.select,
        component: lazy(() => import('src/views/member/suggestions/select'))
      },
      {
        exact: true,
        path: urls.suggestions.list,
        component: lazy(() => import('src/views/member/suggestions/list'))
      },
      {
        exact: true,
        path: urls.suggestions.single(':id'),
        component: lazy(() => import('src/views/member/suggestions/single'))
      },
      {
        exact: true,
        path: urls.suggestions.location(':id'),
        component: lazy(() => import('src/views/member/suggestions/single/Location'))
      },
      {
        exact: true,
        path: urls.suggestions.photos(':id'),
        component: lazy(() => import('src/views/member/suggestions/single/Photos'))
      },
      {
        exact: true,
        path: urls.suggestions.description(':id'),
        component: lazy(() => import('src/views/member/suggestions/single/Description'))
      },
      {
        exact: true,
        path: urls.suggestions.interests(':id'),
        component: lazy(() => import('src/views/member/suggestions/single/Interests'))
      },
      {
        exact: true,
        path: urls.suggestions.attributes(':id'),
        component: lazy(() => import('src/views/member/suggestions/single/Attributes'))
      },
      {
        exact: true,
        path: urls.account,
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: base,
        component: () => <Redirect to={urls.dashboard} />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default memberRoutes;
