import prependUrlBaseProxy from './prependBaseProxy';

export const base = '/app/member/';

const urlsWithoutBase = {
  base,
  dashboard: 'dashboard',
  suggestions: {
    select: 'suggestions/select',
    list: 'suggestions/list',
    single: (id) => `suggestions/${id}`,
    location: (id) => `suggestions/${id}/location`,
    photos: (id) => `suggestions/${id}/photos`,
    description: (id) => `suggestions/${id}/description`,
    interests: (id) => `suggestions/${id}/interests`,
    attributes: (id) => `suggestions/${id}/attributes`
  },
  account: 'account'
};

export const urls = new Proxy(urlsWithoutBase, prependUrlBaseProxy);

export default urls;
