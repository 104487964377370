/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, List, ListSubheader, makeStyles } from '@material-ui/core';
import {
  User as UserIcon,
  PieChart as PieChartIcon,
  MapPin as PinIcon,
  Users as MembersIcon,
  Image as ImageIcon,
  Award
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import urls from 'src/routes/adminUrls';

const sections = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: urls.dashboard
      }
    ]
  },
  {
    subheader: 'Upravljanje',
    items: [
      {
        title: 'Izletne točke',
        icon: PinIcon,
        href: urls.pois.list,
        items: [
          {
            title: 'Seznam',
            href: urls.pois.list
          },
          {
            title: 'Predlogi',
            href: urls.pois.suggestions
          },
          {
            title: 'Kategorizacija',
            href: urls.pois.taxonomies
          },
          {
            title: 'Dodaj novo',
            href: urls.pois.create
          }
        ]
      },
      {
        title: 'Fotografije',
        icon: ImageIcon,
        href: urls.photos.list
      },
      {
        title: 'Nagradni natečaj',
        icon: Award,
        href: urls.contest.photos,
        items: [
          {
            title: 'Fotografije',
            href: urls.contest.photos
          },
          {
            title: 'Sodelujoči',
            href: urls.contest.uploaders
          }
        ]
      },
      {
        title: 'Uporabniki',
        icon: MembersIcon,
        href: urls.users.list,
        items: [
          {
            title: 'Seznam',
            href: urls.users.list
          },
          {
            title: 'Dodaj uporabnika',
            href: urls.users.create
          }
        ]
      }
    ]
  },
  {
    subheader: 'Pages',
    items: [
      {
        title: 'Račun',
        href: urls.account,
        icon: UserIcon
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth + pathname;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo full />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
