import React, { lazy } from 'react';
import GuestGuard from 'src/routes/guards/GuestGuard';
import MemberOrAdminGuard from 'src/routes/guards/MemberOrAdminGuard';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';

const commonRoutes = [
  {
    exact: true,
    path: '/404',
    layout: MainLayout,
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: LoginView
  },
  {
    exact: true,
    guard: MemberOrAdminGuard,
    path: '/app',
    layout: MainLayout,
    component: lazy(() => import('src/components/LoadingScreen'))
  }
];

export default commonRoutes;
