import React from 'react';
import { makeStyles, Badge } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import BaseIconButton from 'src/components/buttons/BaseIconButton';
import urls from 'src/routes/memberUrls';
import { Link as RouterLink } from 'react-router-dom';
import { usePois } from 'src/adapters/api/hooks';
import { Inbox as InboxIcon, Home as HomeIcon } from 'react-feather';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto'
  }
}));

export default function BottomAppBar() {
  const classes = useStyles();
  const { user } = useAuth();
  const { data: suggestions } = usePois([], {
    filter: { created_by: user.id, suggestion_state: 'suggesting' }
  });

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <BaseIconButton color="inherit" aria-label="open drawer" to={urls.dashboard}>
          <HomeIcon />
        </BaseIconButton>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fabButton}
          to={urls.suggestions.select}
          component={RouterLink}
        >
          <AddIcon />
        </Fab>
        <div className={classes.grow} />
        <BaseIconButton color="inherit" to={urls.suggestions.list}>
          <Badge badgeContent={suggestions && suggestions.length} color="primary">
            <InboxIcon />
          </Badge>
        </BaseIconButton>
      </Toolbar>
    </AppBar>
  );
}
