import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import commonRoutes from './commonRoutes';
import adminRoutes from './adminRoutes';
import memberRoutes from './memberRoutes';
import unmatchedRoutes from './unmatched-routes';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

// TODO: Maybe check if any of routes are duplicated
const routes = [...commonRoutes, ...memberRoutes, ...adminRoutes, ...unmatchedRoutes];

export default routes;
