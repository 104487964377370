import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Hidden } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import useAuth from 'src/hooks/useAuth';
import TermsDialog from 'src/components/dialogs/TermsDialog';
import api from 'src/adapters/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingBottom: 132
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { user, updateUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleTermsSubmit = () => {
    setIsLoading(true);
    api
      .update('users', {
        id: user.id,
        terms_consent: new Date(),
        gdpr_consent: new Date()
      })
      .then((response) => {
        updateUser(response.data);
      });
  };

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

      <Hidden mdUp>
        <BottomBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Hidden>

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>

      {!user.terms_consent && <TermsDialog handleConfirm={handleTermsSubmit} dsiabled={isLoading} />}
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
