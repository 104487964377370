import client from './client';
import { loginUrl, currentUserUrl } from './config';

export function get({ resourceType, id = null, includes = [], ...rest }) {
  const postParams = { ...rest };
  if (includes.length > 0) postParams.include = includes.join(',');

  if (id) {
    return client.find(resourceType, id, postParams);
  } else {
    return client.findAll(resourceType, postParams);
  }
}

export const login = function ({ email, password }) {
  const postObject = {
    username: email,
    password,
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    grant_type: 'password',
    scope: ''
  };
  return client.axios.post(loginUrl, postObject);
};

export const fetchCurrentUser = function () {
  return client.request(currentUserUrl, 'GET', {});
};

export const setAuthorizationToken = function (token) {
  client.headers['Authorization'] = `Bearer ${token}`;
  client.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return client;
};

export const unsetAuthorizationToken = function () {
  client.headers['Authorization'] = ``;
  delete client.axios.defaults.headers.common.Authorization;
  return client;
};

export const getElevation = ({ lat, lng }) => {
  return client.axios.get(`geo/elevation?lat=${lat}&lng=${lng}`);
};

export default { get, login, fetchCurrentUser, setAuthorizationToken, unsetAuthorizationToken, getElevation };
