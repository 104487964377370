import GuestGuard from 'src/routes/guards/GuestGuard';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import { Redirect } from 'react-router-dom';

const umatched = [
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: LoginView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default umatched;
