import JsonApi from 'devour-client';
import { transformURLUnderscoresToDashes, customDeserializer } from './middleware';
import schema from './schema';

export const apiOptions = {
  apiUrl: process.env.REACT_APP_API_BASE,
  middleware: [transformURLUnderscoresToDashes],
  logger: true,
  pluralize: false,
  errorBuilder: (error) => {
    const { title, detail, status } = error;
    return { title, detail, status };
  }
};

const jsonApi = new JsonApi(apiOptions);
jsonApi.axios.defaults.headers.common.Accept = 'application/vnd.api+json';
jsonApi.axios.defaults.baseURL = apiOptions.apiUrl;

jsonApi.replaceMiddleware('response', customDeserializer);

// Register all types with API
for (const resource in schema) {
  if (Object.hasOwnProperty.call(schema, resource)) {
    jsonApi.define(resource, schema[resource]);
  }
}

export default jsonApi;
